
export enum SortDirection {
    asc = 'asc',
    desc = 'desc'
}

export interface IPagingParams {
    page?: number
    page_size?: number
    sort_column?: string
    sort_direction?: SortDirection
}

export interface IPagingResponse<T> {
    data: T[]
    total_count: number
}