import { IAuthorizationModel } from "./authorization.model"
import { IPagingParams } from "./paging.model"
import { IWebsiteModel } from "./website.model"

export enum RenderType
{
    OnDemand = 1,
    CacheHit = 2,
    Sitemap = 3,
    ReCache = 4
}

export enum RenderSortColumns
{
    date_created = 'date_created',
    type = 'type',
    websiteDomain = 'website.domain',
    path = 'path',
    milliseconds = 'milliseconds'
}

export interface IRenderPagingParams extends IPagingParams
{
    website_ids?: string[]
    start_date?: string
    end_date?: string
}

export interface IRenderModel
{
    render_id: string
    date_created: Date
    type: RenderType
    website: IWebsiteModel
    path: string
    url: string
    milliseconds: number
    user_agent?: string
    
}

export enum RenderFunctionResponseType
{
    HTML = 'html',
    JSON = 'json'
}

export interface RenderFunctionArgument {
    url: string
    auth: IAuthorizationModel
    response_type?: RenderFunctionResponseType
    cache_enabled?: boolean
}

export interface IRenderFunctionResponse
{
    body: string
}