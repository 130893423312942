
export enum UserType
{
  Admin = 1,
  Viewer = 2
}

export interface IAccountModel
{
    account_id: string
    date_created: Date
    name: string
    email: string
    type: UserType
}

export interface IAccountPayload
{
    name?: string
    email?: string
}