import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { DateFormatPipe, DateTimeFormatPipe } from './pipes/date-format.pipe';
import { ApiService } from './services/api.service';
import { RenderService } from './services/render.service';
import { MatNativeDateModule } from '@angular/material/core';
import { AccountService } from './services/account.service';
import { AuthService } from './services/auth.service';

@NgModule({
  declarations: [
    DateFormatPipe,
    DateTimeFormatPipe
  ],
  imports: [
    CommonModule,
    MatNativeDateModule
  ],
  exports: [
    MatNativeDateModule,
    DateFormatPipe,
    DateTimeFormatPipe
  ]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        AuthService,
        ApiService,
        RenderService,
        AccountService
      ]
    };
  }
}
