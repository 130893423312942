import { Injectable } from "@angular/core";
import { BehaviorSubject, combineLatest, map, shareReplay, switchMap } from "rxjs";
import { ApiService } from "./api.service";
import { AuthService } from "./auth.service";

@Injectable()
export class AccountService {

    private readonly account_id_subject = new BehaviorSubject<string | undefined>(localStorage.getItem('current_account') ?? undefined)

    constructor(
        private readonly auth: AuthService,
        private readonly api: ApiService
    ) {

    }

    public readonly accounts$ = this.auth.isAuthed$.pipe(
        switchMap(authed => {
            if (authed) {
                return this.api.getAccounts()
            }
            return []
        }),
        shareReplay(1)
    )


    public readonly currentAccount$ = combineLatest([
        this.account_id_subject,
        this.accounts$
    ]).pipe(
        map(([account_id, accounts]) => {
            if(account_id && accounts.length > 0)
            {
                return accounts.find(f=>f.account_id == account_id)
            }
            return undefined
        }),
        shareReplay(1)
    )

    public setAccount(account_id: string) {
        this.account_id_subject.next(account_id)
        localStorage.setItem('current_account', account_id)
    }
}