import { HttpClient, HttpContext, HttpContextToken, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IAccountModel, IAccountPayload, IAuthorizationModel, IContactPayload, IPagingResponse, IRenderModel, IRenderPagingParams, IStripeCustomerPortalModel, ISubscriptionModel, IWebsiteModel, IWebsitePagingParams, IWebsitePayload } from '@renderstatic/api-shared';
import { Observable, catchError, take, throwError } from "rxjs";
import { environment } from "../../../../environments/environment";

export const REQUIRES_ACCOUNT_TOKEN = new HttpContextToken<boolean>(() => false)

@Injectable()
export class ApiService {

    constructor(private readonly client: HttpClient) {

    }

    private get<TReturn>(path: string[], opts?: {
        version?: 'v1',
        params?: unknown
        requires_account?: boolean
    }): Observable<TReturn> {
        const params = new HttpParams({ fromObject: opts?.params ? { ...opts.params } : undefined })
        const context = new HttpContext()
        if (opts?.requires_account) {
            context.set(REQUIRES_ACCOUNT_TOKEN, opts.requires_account)
        }
        return this.client.get<TReturn>(`${environment.api_base}/${opts?.version ?? 'v1'}/${path.join('/')}`, { params, context: context }).pipe(
            take(1),
            catchError((err: HttpErrorResponse) => throwError(() => err.error))
        )
    }

    private put<TReturn, TPayload>(path: string[], payload: TPayload, opts?: {
        version?: 'v1'
        requires_account?: boolean
    }): Observable<TReturn> {
        const context = new HttpContext()
        if (opts?.requires_account) {
            context.set(REQUIRES_ACCOUNT_TOKEN, opts.requires_account)
        }

        return this.client.put<TReturn>(`${environment.api_base}/${opts?.version ?? 'v1'}/${path.join('/')}`, payload, { context }).pipe(
            take(1),
            catchError((err: HttpErrorResponse) => throwError(() => err.error))
        )
    }

    private patch<TReturn, TPayload>(path: string[], payload: TPayload, opts?: {
        version?: 'v1'
        requires_account?: boolean
    }): Observable<TReturn> {
        const context = new HttpContext()
        if (opts?.requires_account) {
            context.set(REQUIRES_ACCOUNT_TOKEN, opts.requires_account)
        }

        return this.client.patch<TReturn>(`${environment.api_base}/${opts?.version ?? 'v1'}/${path.join('/')}`, payload, { context }).pipe(
            take(1),
            catchError((err: HttpErrorResponse) => throwError(() => err.error))
        )
    }

    public getAccounts(): Observable<IAccountModel[]> {
        return this.get<IAccountModel[]>(['accounts'])
    }


    public getAccount(account_id: string): Observable<IAccountModel> {
        return this.get<IAccountModel>(['accounts', account_id])
    }

    public updateAccount(account_id: string, payload: IAccountPayload): Observable<unknown> {
        return this.patch<unknown, IAccountPayload>(['accounts', account_id], payload)
    }

    public getWebsites(params: IWebsitePagingParams): Observable<IPagingResponse<IWebsiteModel>> {
        return this.get<IPagingResponse<IWebsiteModel>>(['websites'], { params, requires_account: true })
    }

    public getWebsite(id: string): Observable<IWebsiteModel> {
        return this.get<IWebsiteModel>(['websites', id], { requires_account: true })
    }

    public createWebsite(payload: IWebsitePayload): Observable<IWebsiteModel> {
        return this.put<IWebsiteModel, IWebsitePayload>(['websites'], payload, { requires_account: true })
    }

    public updateWebsite(id: string, payload: IWebsitePayload): Observable<IWebsiteModel> {
        return this.patch<IWebsiteModel, IWebsitePayload>(['websites', id], payload, { requires_account: true })
    }

    public verifyWebsite(id: string): Observable<unknown> {
        return this.get(['websites', id, 'verify'], { requires_account: true })
    }

    public getAuthorization(): Observable<IAuthorizationModel> {
        return this.get<IAuthorizationModel>(['authorizations'], { requires_account: true })
    }

    public refreshAuthorization(): Observable<IAuthorizationModel> {
        return this.put<IAuthorizationModel, unknown>(['authorizations'], {}, { requires_account: true })
    }

    public getRenders(params: IRenderPagingParams): Observable<IPagingResponse<IRenderModel>> {
        return this.get<IPagingResponse<IRenderModel>>(['renders'], { params, requires_account: true })
    }

    public getRender(id: string): Observable<IRenderModel> {
        return this.get<IRenderModel>(['renders', id], { requires_account: true })
    }

    public getStripeCustomerPortal(): Observable<IStripeCustomerPortalModel> {
        return this.get<IStripeCustomerPortalModel>(['stripe', 'customer-portal'], { requires_account: true })
    }

    public startSubscription(): Observable<unknown> {
        return this.put(['stripe', 'subscription'], {}, { requires_account: true })
    }

    public getSubscription(): Observable<ISubscriptionModel> {
        return this.get(['stripe', 'subscription'], { requires_account: true })
    }


    public contact(payload: IContactPayload): Observable<unknown> {
        return this.put<unknown, IContactPayload>(['contact'], payload)
    }
}