import { ResourcesConfig } from 'aws-amplify';

export const amplify: ResourcesConfig = {
  Auth: {
    Cognito: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: 'us-east-1:94d7c268-206b-489d-b823-e29ad85592cc',

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'us-east-1_0w2dNzEA0',

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolClientId: '6e0l8i9j3l6iu5v4kp8crh2eis',

      // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
      // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
      signUpVerificationMethod: 'code', // 'code' | 'link'
      loginWith: {
        oauth: {
          domain: 'auth.renderstatic.com',
          scopes: [
            'email',
            'profile',
            'openid',
            'aws.cognito.signin.user.admin',
          ],
          redirectSignIn: ['https://www.renderstatic.com/dashboard'],
          redirectSignOut: ['https://www.renderstatic.com/login'],
          responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
        },
      },
    },
  },
};

export const environment = {
  name: 'prod',
  api_base: 'https://api.renderstatic.com',
  render_base: 'https://api.renderstatic.com',
};
