import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IRenderFunctionResponse, RenderFunctionArgument, RenderFunctionResponseType } from "@renderstatic/api-shared";
import { environment } from "../../../../environments/environment";
import { Observable, catchError, take, throwError } from "rxjs";


@Injectable()
export class RenderService {

    constructor(private readonly client: HttpClient) {

    }

    public render(arg: RenderFunctionArgument): Observable<IRenderFunctionResponse> {

        const params = new HttpParams({
            fromObject: { 
                url: encodeURIComponent(arg.url),
                cache_enabled: arg.cache_enabled ?? true,
                response_type:RenderFunctionResponseType.JSON
             }
        })

        const headers = new HttpHeaders({
            Authorization: arg.auth.authorization_id
        })

        return this.client.get<IRenderFunctionResponse>(`${environment.render_base}/render`, { params, headers }).pipe(
            take(1),
            catchError((err: HttpErrorResponse) => throwError(() => err.error))
        )
    }
}