import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  Observable,
  ReplaySubject,
  catchError,
  from,
  map,
  of,
  shareReplay,
} from 'rxjs';
import {
  getCurrentUser,
  fetchAuthSession,
  AuthUser,
  AuthSession,
  signOut,
} from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';

@Injectable()
export class AuthService {
  private readonly current_user_subject = new ReplaySubject<
    AuthUser | undefined
  >();

  public readonly currentUser$ = this.current_user_subject.pipe(shareReplay(1));

  public readonly isAuthed$: Observable<boolean> = this.currentUser$.pipe(
    map((user) => user != undefined),
    shareReplay(1),
  );

  constructor(private readonly route: ActivatedRoute) {
    fetchAuthSession().then((auth) => {
      if (auth) {
        this.refresh_current_auth_user();
      } else if (!this.route.snapshot.queryParamMap.get('code')) {
        this.current_user_subject.next(undefined);
      }
    });

    // Use Hub channel 'auth' to get notified on changes
    Hub.listen('auth', ({ payload: { event } }) => {
      console.log(`${event}`);
      if (
        event === 'signedIn' ||
        event === 'signInWithRedirect' ||
        event === 'tokenRefresh'
      ) {
        this.refresh_current_auth_user();
      }
    });
  }

  private refresh_current_auth_user() {
    getCurrentUser().then((user) => {
      this.current_user_subject.next(user);
    });
  }

  public getCurrentSession(): Observable<AuthSession | undefined> {
    return from(fetchAuthSession()).pipe(
      catchError((err) => {
        if (err instanceof Error) {
          console.log(`currentSession$: ${err.message}`);
        }
        return of(undefined);
      }),
    );
  }

  public logout(): Promise<unknown> {
    this.current_user_subject.next(undefined);
    return signOut();
  }
}
