import { Pipe, PipeTransform } from "@angular/core"
import { format } from "date-fns"

@Pipe({
    name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

    transform(date: Date | undefined, f = 'P'): string {
        if (date) {
            return format(date, f)
        }

        return ''
    }
}

@Pipe({
    name: 'dateTimeFormat'
})
export class DateTimeFormatPipe extends DateFormatPipe implements PipeTransform {

    override transform(date: Date | undefined, f = 'P, pp'): string {
        return super.transform(date, f)
    }
}
