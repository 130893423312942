import { IPagingParams } from "./paging.model"

export enum WebsiteSortColumns
{
    date_created = 'date_created',
    domain = 'domain',
    sitemap = 'sitemap',
    cache_ttl = 'cache_ttl'
}

export interface IWebsitePagingParams extends IPagingParams
{
    search?: string
}

export interface IWebsiteModel
{
    website_id: string
    date_created: Date
    domain: string
    sitemap?: string
    cache_ttl?: number
    verified?: boolean
}

export interface IWebsitePayload
{
    domain?: string
    sitemap?: string
    cache_ttl?: number
}